import React, { useCallback, useState } from "react";
import * as yup from "yup";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Colors from "../../constants/Colors";
import { ProjectRoutes } from "../../constants/ProjectRoutes";
import { useAuth } from "./hooks/useAuth";
import { AuthForm } from "../../api/types/Login";
import { Checkbox, Stack } from "@mantine/core";
import ExternalLinks from "../../constants/ExternalLinks";

const schema = yup
  .object({
    email: yup.string().required("Required"),
    password: yup.string().required("Required"),
  })
  .required();

export const Register = () => {
  const { handleRegister } = useAuth();
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = useCallback(
    async (payload: AuthForm) => {
      await handleRegister(payload);
    },
    [handleRegister]
  );

  return (
    <Wrapper>
      <LogoText>Mazuri</LogoText>
      <Container>
        <Header>Create your Mazuri account</Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ButtonsBox>
            <InputWrapper>
              <StyledLabel>Email</StyledLabel>
              <StyledInput
                placeholder={"john.smith@google.com"}
                {...register("email")}
              ></StyledInput>
            </InputWrapper>

            <InputWrapper>
              <StyledLabel>Password</StyledLabel>
              <StyledInput
                type={"password"}
                placeholder={"Password"}
                {...register("password")}
              ></StyledInput>
            </InputWrapper>

            {/* <InputWrapper>
              <StyledLabel>
                I have read and agree to the{" "}
                <a href={ExternalLinks.terms}>Terms of Service</a> and our{" "}
                <a href={ExternalLinks.privacy}>Privacy Policy</a> of this site
              </StyledLabel>

              <Checkbox
                labelPosition="left"
                label="Terms of Service"
                color="yellow"
                checked={isTermsChecked}
                onChange={(event) =>
                  setIsTermsChecked(event.currentTarget.checked)
                }
              />
              <Checkbox
                labelPosition="left"
                label="Privacy Policy"
                color="yellow"
                checked={isPrivacyChecked}
                onChange={(event) =>
                  setIsPrivacyChecked(event.currentTarget.checked)
                }
              />
            </InputWrapper> */}

            <StyledLabel>
              I have read and agree to the{" "}
              <a href={ExternalLinks.terms} target="_blank" rel="noreferrer">
                Terms of Service{" "}
              </a>{" "}
              and our{" "}
              <a href={ExternalLinks.privacy} target="_blank" rel="noreferrer">
                Privacy Policy
              </a>{" "}
              of this site
            </StyledLabel>
            <Stack>
              <Checkbox
                labelPosition="left"
                label="Terms of Service"
                color="yellow"
                checked={isTermsChecked}
                onChange={(event) =>
                  setIsTermsChecked(event.currentTarget.checked)
                }
              />
              <Checkbox
                labelPosition="left"
                label="Privacy Policy"
                color="yellow"
                checked={isPrivacyChecked}
                onChange={(event) =>
                  setIsPrivacyChecked(event.currentTarget.checked)
                }
              />
            </Stack>

            <CustomButton
              type={"submit"}
              active={isTermsChecked && isPrivacyChecked}
            >
              Register
            </CustomButton>
          </ButtonsBox>
        </Form>

        {/* <CustomText>
          You acknowledge that you read, and agree to our{" "}
          <CustomLink to={"/"}>Terms of Service</CustomLink> and our{" "}
          <CustomLink to={"/"}>Privacy Policy</CustomLink>
        </CustomText> */}
        <Divider />

        <CustomText>
          Already have an account?{" "}
          <CustomLink to={ProjectRoutes.SignIn}>Log in</CustomLink> instead
        </CustomText>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.background};
  height: 100%;

  width: 100%;

  label {
    color: white;
  }
`;

const Form = styled.form``;
const LogoText = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 65px;
  color: ${Colors.textWhite};
`;
const Container = styled.div`
  width: 480px;
  height: auto;
  padding: 40px;
  position: relative;
  background-color: rgb(23, 24, 29);
  border-radius: 4px;
  border: 1px solid rgba(255, 254, 241, 0.1);
  backdrop-filter: blur(100px);
  box-shadow: 0px 0px 100px -20px rgba(150, 104, 64, 0.75);
`;

const Header = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: ${Colors.textWhite};
  margin: 0px 0px 30px;
`;

const ButtonsBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const CustomButton = styled.button<any>`
  height: 49px;
  display: flex;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid
    ${(props) =>
      props.active ? "rgb(161, 119, 49)" : "rgba(255, 254, 241, 0.2)"};
  background: ${(props) =>
    props.active
      ? "rgb(35, 32, 33)"
      : "radial-gradient(202.04% 202.04% at 50% 111.22%, rgba(255, 254, 241, 0.1) 0%, rgba(120, 120, 120, 0.1) 23.95%);"};

  outline: none !important;
  transition: border-color 0.2s ease 0s, background-color 0.3s ease 0s;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.active ? "rgb(242, 177, 50)" : Colors.textWhite)};

  &:hover {
    ${(props) =>
      props.active
        ? "border-color: rgb(193, 141, 53);    background-color: rgb(38, 35, 33);"
        : `background: radial-gradient(202.04% 202.04% at 50% 111.22%, rgba(255, 254, 241, 0.2) 0%, rgba(120, 120, 120, 0.2) 23.95%);`}
  }
`;
const CustomText = styled.p`
  margin: 20px auto 0px;
  max-width: 320px;
  text-align: center;
  color: ${Colors.textGray};
  font-size: 13.3px;
  line-height: 160%;
`;
const CustomLink = styled(Link)`
  text-decoration: none;
  color: ${Colors.textWhite};
`;
const Divider = styled.div`
  width: 120px;
  height: 1px;
  background-color: rgba(255, 254, 241, 0.1);
  margin: 20px auto 0px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled.input`
  padding: 0px 15px;
  height: 48px;
  width: 100%;
  outline: none;
  cursor: text;
  margin: 0px;
  color: rgb(255, 254, 241);
  background-color: rgba(255, 254, 241, 0.05);
  border-radius: 4px;
  border: 1px solid rgba(255, 254, 241, 0.1);
  font-size: 15px;
`;

const StyledLabel = styled.label`
  font-weight: 500;
  font-size: 14px;

  line-height: 20px;
  text-align: left;
  color: ${Colors.textWhite};
  margin: 0px 8px 8px;

  a {
    color: rgb(193, 141, 53);
  }
`;
