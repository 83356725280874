import React from "react";

const ArrowSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="7"
    height="11"
    viewBox="0 0 7 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.6352 0.671875L5.90281 5.12605C5.99609 5.24266 6.06605 5.38258 6.06605 5.5225C6.06605 5.66242 5.99609 5.80234 5.90281 5.89563L1.6352 10.3498C1.42531 10.583 1.05219 10.583 0.842305 10.3731C0.609102 10.1632 0.609102 9.81344 0.818984 9.58023L4.7368 5.49918L0.818984 1.44145C0.609102 1.23156 0.609102 0.858438 0.842305 0.648555C1.05219 0.438672 1.42531 0.438672 1.6352 0.671875Z"
      fill="currentColor"
    ></path>
  </svg>
);

export default ArrowSvg;
