import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import tokenStorage from "../../../helpers/tokenStorage";
import {User} from "../../../api/types/Login";

const Window = window as any

// Types

localStorage.setItem("NETWORK_ID", Window.ethereum?.networkVersion);

export interface AuthState {
  isLoggedIn: boolean;
  connected: boolean;
  user: User | null;
  network: any;
}

// Initial State
const initialState: AuthState = {
  isLoggedIn: !!tokenStorage.getToken(),
  connected: false,
  user: null,
  network: null,
};

// Slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setConnect: (state, action: PayloadAction<boolean>) => {
      state.connected = action.payload;
    },
    setCurrentNetwork: (state, action) => {
      state.network = action.payload;
    },
    logout: (state) => {
      state.isLoggedIn = false
      state.user = null
    },
    login: (state, action) => {
      state.isLoggedIn = true
      state.user = action.payload
    }
  },
});

export const authReducers = authSlice.reducer;
export const authActions = authSlice.actions;

