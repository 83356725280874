import { Link } from "react-router-dom";
import styled from "styled-components";
import { ProjectRoutes } from "../../constants/ProjectRoutes";
import Colors from "../../constants/Colors";

const Header = () => {
  return (
    <Container>
      <Inner>
        <Link to={"/"}>
          <Logo>MazuriPortal</Logo>
        </Link>

        <Links>
          <StyledLink to={"/"}>Blog </StyledLink>
          <StyledLink to={ProjectRoutes.SignIn} className={"marked"}>
            Sign-in
          </StyledLink>
        </Links>
      </Inner>
    </Container>
  );
};
export default Header;

const Container = styled.nav`
  z-index: 999;
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;

  display: flex;
  padding: 10px 15px;

  @media (min-width: 991px) {
    padding: 14px 35px;
  }
`;
const Inner = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.div`
  font-weight: bold;

  color: ${Colors.textWhite};
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledLink = styled(Link)<{ mark?: true | undefined }>`
  align-items: center;
  margin-left: 5px;
  padding: 12px 15px;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.1;
  transition: color 0.15s;
  display: flex;
  color: rgba(255, 254, 241, 0.5);

  &:hover {
    color: #fffef1;
    &.marked {
      color: #f2b132cc;
    }
  }
  &.marked {
    color: #f2b132;
  }
`;
