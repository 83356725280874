import React from 'react';
import './styles/App.css';
import {BrowserRouter} from "react-router-dom";
import {Web3Provider} from "@ethersproject/providers";
import {Web3ReactProvider} from "@web3-react/core";
import {Provider as ReduxProvider} from "react-redux";
import Api from "./api";
import {store} from "./store";
import RootRouter from "./features/Routes";
import {QueryClient, QueryClientProvider} from "react-query";
import {NotificationsProvider} from "@mantine/notifications";

function getLibrary(provider: any): Web3Provider {
  return new Web3Provider(provider);
}

const queryClient = new QueryClient();

export const api = new Api()


function App() {
  return (
    <NotificationsProvider>
      <QueryClientProvider client={queryClient}>
        <ReduxProvider store={store}>
          <Web3ReactProvider getLibrary={getLibrary}>
            <BrowserRouter>
              <RootRouter/>
            </BrowserRouter>
          </Web3ReactProvider>
        </ReduxProvider>
      </QueryClientProvider>
    </NotificationsProvider>
  );
}

export default App;
