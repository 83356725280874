import {useCallback} from 'react';
import {authActions} from '../store';
import {useAppDispatch, useAppSelector} from "../../../store";


export default function useAuthStore() {
  const state = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()

  const setLoggedIn = useCallback((status: boolean) => {
    dispatch(authActions.setLoggedIn(status))
  }, [dispatch])

  const setUser = useCallback((user: any) => {
    dispatch(authActions.setUser(user))
  }, [dispatch])

  const setConnect = useCallback((status: boolean) => {
    dispatch(authActions.setConnect(status))
  }, [dispatch])
  //
  const setCurrentNetwork = useCallback((network: string) => {
    dispatch(authActions.setCurrentNetwork(network))
  }, [dispatch])

  const logout = useCallback(() => {
    dispatch(authActions.logout)
  }, [dispatch])

  const logIn = useCallback((payload: any) => {
    dispatch(authActions.login(payload))
  }, [dispatch])


  return {
    ...state,
    setLoggedIn,
    setUser,
    setConnect,
    setCurrentNetwork,
    logout,
    logIn
  }
}
