class TokenStorage {
    // На момент разработки будем хранить токен в local storage браузера
    constructor() {
        this.storage = window.localStorage;
        this.storageKey = "auth-token";
    }

    getToken() {
        return this.storage.getItem(this.storageKey);
    }

    setToken(token) {
        this.storage.setItem(this.storageKey, token);
    }

    removeToken() {
        this.storage.removeItem(this.storageKey);
    }
}

const tokenStorage = new TokenStorage()

export default tokenStorage;
