import {Stack} from "@mantine/core"
import {showNotification} from "@mantine/notifications"
import {ReactNode} from "react"
import CodeBlock from "./CodeBlock";
import MessageBlock from "./MessageBlock";


type ErrorBodyProps = {
  title?: ReactNode;
  message?: ReactNode;
  details?: ReactNode;
}


export function createErrorBody({title, message, details}: ErrorBodyProps) {
  return (
    <Stack spacing={4}>
      <MessageBlock message={message}/>
      <CodeBlock title={title} message={message} details={details}/>
    </Stack>
  )
}

type THandleProps = {
  id?: string;
  title: ReactNode
  message?: ReactNode;
  details?: ReactNode;
}

export function handleError({id, title, details, message}: THandleProps) {
  showNotification({
    id: id ?? "error-handler",
    title,
    color: "red",
    autoClose: 10000,
    message: createErrorBody({
      title,
      details,
      message,
    }),
    styles: () => ({
      root: {
        maxHeight: "unset!important",
        backgroundColor: `rgb(23, 24, 29)`,
        borderColor: `red`
      },
      title: {
        color: "red"
      }
    })
  })
}
