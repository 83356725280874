import {useCallback, useEffect} from "react";

import useAuthStore from "../features/Auth/hooks/useAuthStore";
import {connector} from "../utils/Connectors";
import {useWeb3React} from "@web3-react/core";
import {useLocation} from "react-router-dom";
import {ProjectRoutes} from "../constants/ProjectRoutes";

export const useAutoConnect = () => {
  const {connected, setConnect} = useAuthStore()
  const {activate} = useWeb3React()
  const {pathname} = useLocation()
  const checkConnect = useCallback(async() => {
    const status = await connector.isAuthorized()
    setConnect(status)
    if(pathname.includes(ProjectRoutes.account)) {
      await activate(connector)
    }

  }, [setConnect, pathname, activate])

  useEffect(() => {
    checkConnect()
  }, [checkConnect])

  return connected;
};

