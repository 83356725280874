import { useQuery } from "react-query";
import {api} from "../../../App";

export const useUser = () => {
  const { data, refetch, isLoading, isError, remove } = useQuery(["ACCOUNT"], () => api.auth.getUser(), {retry: 0});

  return {
    data,
    refetch,
    isLoading,
    isError,
    remove,
  };
};
