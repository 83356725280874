import {useCallback, useMemo} from "react";
import useAuthStore from "./useAuthStore";
import TokenStorage from "../../../helpers/tokenStorage";
import {useUser} from "./useUser";
import {AuthForm} from "../../../api/types/Login";
import {api} from "../../../App";
import tokenStorage from "../../../helpers/tokenStorage";
import {handleError} from "../../ErrorHandler/ErrorHandler";

export const useAuth = () => {
    const { logout: logOut, isLoggedIn, user, logIn } = useAuthStore();
    const { remove, refetch } = useUser();

    const isAuthorized = useMemo(() => {
        return Boolean(isLoggedIn && user);
    }, [isLoggedIn, user]);


    const logout = useCallback(() => {
        TokenStorage.removeToken();
        remove();
        logOut();
    }, [remove, logOut]);


    const checkAuth = useCallback(async () => {
        return new Promise(async(resolve, reject) => {
            try {
                const token = TokenStorage.getToken();
                console.log("checking auth token", token);
                if (!token) return reject('NO AUTH TOKEN')

                const { data: authUser } = await refetch();

                if (!authUser) return reject('NO USER DATA')

                logIn(authUser);
                return resolve(authUser);
            } catch (e) {
                logout();
                reject(e)
            }
        })

    }, [refetch, logIn, logout]);

    const handleRegister = useCallback(async(payload: AuthForm) => {
        try {
            const response = await api.auth.register(payload)
            tokenStorage.setToken(response.token)
            await checkAuth()
        } catch (e: any) {
            const error = e.response.data
            handleError({id: error.code, title: 'Error occured', message: error.error})
        }
    }, [checkAuth])

    const handleLogin = useCallback(async(payload: AuthForm) => {
        try {
            const response = await api.auth.login(payload)
            tokenStorage.setToken(response.token)
            await checkAuth()
        } catch (e: any) {
            const error = e.response.data
            handleError({id: error.code, title: 'Error', message: error.error})
        }
    }, [checkAuth])

    return {
        user,
        isLoggedIn,
        logout,
        logIn,
        checkAuth,
        isAuthorized,
        handleRegister,
        handleLogin
    }
}
