import { Code, Group, Stack, Text, Button } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { ReactNode } from "react";

type Props = {
  title?: ReactNode;
  message?: ReactNode;
  details?: ReactNode;
}

export default function CodeBlock({ details, message, title }: Props) {
  const clipboard = useClipboard({
    timeout: 5000
  })

  if (!details) return null

  const clipboardObject = JSON.stringify({
    title,
    message: message?.toString(),
    details: details?.toString(),
  })

  return (
    <Stack mt={8} spacing={0}>
      <Text size="sm" color="dark" weight={600}>Error Details:</Text>
      <Text size={"xs"} color="dimmed">Copy the error code below and send it to support</Text>
      <Code color="gray" my={8}>
        <Text size="xs" sx={{ fontFamily: "monospace" }} dangerouslySetInnerHTML={{ __html: details as any }} />
      </Code>
      <Group position="right">
        <Button disabled={clipboard.copied} type="button" variant="subtle" size="xs" color={clipboard.copied ? "green" : "blue"} onClick={() => clipboard.copy(clipboardObject)}>
          {clipboard.copied ? "Copied" : "Copy Details"}
        </Button>
      </Group>
    </Stack>
  )
}
