import styled from "styled-components";
import Colors from "../../constants/Colors";
import {useWallet} from "../Auth/hooks/useWallet";
import {useCallback} from "react";
import {useWeb3React} from "@web3-react/core";
import {connector} from "../../utils/Connectors";

export const WallectConnector = () => {
  const { connect } = useWallet()
  const {activate} = useWeb3React()

  const handleConnect = useCallback(async () => {
    try {
      await activate(connector)
      await connect()
    } catch (e:any) {
      console.log(`ERROR`, e)
      const error = e.response
      console.log(`ERROR`, error)
      // handleError({id: })
    }
  }, [activate, connect])

  return (
    <Wrapper>
      <LogoText>Mazuri</LogoText>
      <Container>
        <Header>Connect wallet to your account</Header>
        <CustomText>
          <b style={{fontSize: 15}}>You are logged in. <br/> You can use this email and your password to auth in-game.</b><br/>
          If you want perform actions related to chain operations please follow instructions
          <br/>
          <a target={'_blank'} rel="noreferrer" style={{textDecoration: 'underline', color: "rgb(161, 119, 49)"}} href={'https://doc.clickup.com/4702025/p/h/4ffu9-226504/58ded76ba0decea'}>Blockchain instructions</a>
        </CustomText>
        <CustomButton style={{marginTop: 16}} onClick={handleConnect} active>
          Connect wallet
        </CustomButton>

    </Container>
</Wrapper>
)
  ;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.background};
  height: 100%;

  width: 100%;
`;

const LogoText = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 65px;
  color: ${Colors.textWhite};
`;
const Container = styled.div`
  width: 480px;
  height: auto;
  padding: 40px;
  position: relative;
  background-color: rgb(23, 24, 29);
  border-radius: 4px;
  border: 1px solid rgba(255, 254, 241, 0.1);
  backdrop-filter: blur(100px);
  box-shadow: 0px 0px 100px -20px rgba(150, 104, 64, 0.75);
`;

const Header = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: ${Colors.textWhite};
  margin: 0px 0px 30px;
`;

const CustomButton = styled.button<any>`
  height: 49px;
  display: flex;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid ${(props) =>
          props.active ? "rgb(161, 119, 49)" : "rgba(255, 254, 241, 0.2)"};
  background: ${(props) =>
          props.active
                  ? "rgb(35, 32, 33)"
                  : "radial-gradient(202.04% 202.04% at 50% 111.22%, rgba(255, 254, 241, 0.1) 0%, rgba(120, 120, 120, 0.1) 23.95%);"};

  outline: none !important;
  transition: border-color 0.2s ease 0s, background-color 0.3s ease 0s;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
          props.active ? "rgb(242, 177, 50)" : Colors.textWhite};

  &:hover {
    ${(props) =>
            props.active
                    ? "border-color: rgb(193, 141, 53);    background-color: rgb(38, 35, 33);"
                    : `background: radial-gradient(202.04% 202.04% at 50% 111.22%, rgba(255, 254, 241, 0.2) 0%, rgba(120, 120, 120, 0.2) 23.95%);`}
  }
`;

const CustomText = styled.p`
  text-align: center;
  color: ${Colors.textGray};
  font-size: 13.3px;
  line-height: 160%;
`;

