import { Text } from "@mantine/core"
import { ReactNode, createElement } from "react"

type Props = {
  message?: ReactNode
}

export default function MessageBlock({ message }: Props) {
  if (!message) return null

  const displayMessage = createElement("span", {
    dangerouslySetInnerHTML: {
      __html: message
    }
  })

  return (
    <Text size="sm" color="dimmed">{displayMessage}</Text>
  )
}
