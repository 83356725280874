import { Navigate, Route, Routes } from "react-router-dom";
import BlogPage from "../Blog";
import { ProjectRoutes } from "../../constants/ProjectRoutes";
import {Login} from "../Auth/Login";
import {Register} from "../Auth/Register";
import {RestorePassword} from "../RestorePassword/RestorePassword";

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route path={ProjectRoutes.SignIn} element={<Login />} />
      <Route path={ProjectRoutes.SignUp} element={<Register />} />
        <Route path={ProjectRoutes.restorePassword} element={<RestorePassword/>}/>
      {/*<Route path="forgot-password" element={<Forgot />} />*/}
      <Route
        path="*"
        element={<Navigate replace to={ProjectRoutes.SignIn} />}
      />
      <Route path={ProjectRoutes.Blog} element={<BlogPage />} />
      {/*<Route path="forgot-password" element={<Forgot />} />*/}
      <Route path="*" element={<Navigate replace to={ProjectRoutes.Blog} />} />
    </Routes>
  );
}
