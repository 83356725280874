import styled from "styled-components";
import Colors from "../../constants/Colors";
import {useBalance} from "./hooks/useBalance";
import {useUser} from "../Auth/hooks/useUser";
import {useCallback, useMemo, useState} from "react";
import {ethers} from "ethers";
import {api} from "../../App";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {ProfileForm} from "../../api/types/User";
import {useNavigate} from "react-router-dom";
import {ProjectRoutes} from "../../constants/ProjectRoutes";
import {handleError} from "../ErrorHandler/ErrorHandler";
import { Modal, Stack } from "@mantine/core";

const schema = yup
  .object({
    email: yup.string(),
    phone: yup
      .string()
      .test("isValidPhoneNumber", "Incorrect phone number", (value) => {
        if (!value || value.length < 7 || value.length > 15 || value.includes(" ") || /[^0-9]/.test(value)) {
          return false;
        }
        return true;
      })
      .required("Required field"),
  })
  .required();


export const AccountPage = () => {
  // const {data} = useServerConstants()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [refillAmount, setRefillAmount] = useState(0)
  const [withdrawAmount, setWithdrawAmount] = useState(0)
  const {refillBalance} = useBalance()
  const navigate = useNavigate()
  const {data, refetch} = useUser()
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: {errors}
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: data?.email || "",
      phone: data?.phone || "",
    },
  });


  const balance = useMemo(() => {
    if (!data?.balance) return 0
    return ethers.utils.formatEther(data.balance)
  }, [data])


  const handleReffil = useCallback(async () => {
    try {
      if (!refillAmount) {
        handleError({id: "0", title: "Error", message: "Amount must be bigger than 0"})
        return
      }
      setLoading(true)
      const amount = ethers.utils.parseUnits(refillAmount.toString(), 18).toString()
      await refillBalance(amount)
      setLoading(false)
      await refetch()
    } catch (e:any) {
      console.log(e)
      handleError({id: "0", message: e || "unexpected error", title: 'Error'})
      setLoading(false)
    }

  }, [refillAmount, setLoading, refillBalance, refetch])

  const handleWithdraw = useCallback(async () => {
    try {
      if (!withdrawAmount) {
        handleError({id: "0", title: "Error", message: "Amount must be bigger than 0"})
        return
      }
      setLoading(true)
      const amount = ethers.utils.parseUnits(withdrawAmount.toString(), 18).toString()
      await api.user.balanceWithdraw('eth', amount)
      await refetch()
      const timer = setTimeout(() => {
        setLoading(false)
        clearTimeout(timer)
      }, 1000)
    } catch (e) {
      console.log(e)
    }


  }, [withdrawAmount, setLoading, refetch])




  const updateProfile = useCallback(async (payload: ProfileForm) => {
    setLoading(true)
      await api.user.updateProfile(payload).catch((error: any) => {
          console.log(error);
          handleError({ id: error.id, title: error.title, message: error.message || "unexpected error" });
          setModalTitle("Update profile error");
          setModalContent(error.id === 102
              ? "The phone number is invalid or incorrect. Please enter your current phone number. Example: 15551234522"
              : "An unexpected error occurred while updating your profile. Contact technical support or try again later")
          setIsModalOpen(true);
      })
    await refetch()
    setLoading(false)
  }, [setLoading, refetch])


  const verifyPhone = useCallback(() => {
    if (!data?.phone || data?.phone.length < 7 || data?.phone.length > 15 || data?.phone.includes(" ") || /[^0-9]/.test(data?.phone)) {
        setModalTitle("Phone verification error");
        setModalContent("The specified phone number is not valid. Please, provide your current phone number.");
        setIsModalOpen(true);
    } else {
        navigate(ProjectRoutes.phoneVerification)
    }
  }, [navigate, data?.phone])


  return (
    <>
        <Wrapper>
        <LogoText>Mazuri</LogoText>
        <Container>
            <Header>Account</Header>

            <CustomText style={{fontSize: 18, textAlign: 'left', marginBottom: 20}}>
            <b> User info:</b>
            </CustomText>


            <ButtonsBox>
            <Form onSubmit={handleSubmit(updateProfile)}>
                <InputWrapper>
                <StyledLabel>Email</StyledLabel>
                <StyledInput disabled placeholder={"john.smith@google.com"} {...register('email')}></StyledInput>
                </InputWrapper>

                <InputWrapper style={{marginTop: 12}}>
                <StyledLabel>Phone</StyledLabel>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: 'center'}}>
                    <div style={{ width: "100%" }}>
                        <StyledInput error={errors.phone}  disabled={data?.isPhoneVerified} placeholder={"10000001234"} {...register('phone')}></StyledInput>
                        { errors.phone ? <ErrorMessage>{errors.phone.message}</ErrorMessage> : null }
                    </div>
                    {data?.phone && !data?.isPhoneVerified &&
                    <CustomButton onClick={verifyPhone} style={{marginLeft: 12}} active={!loading}>
                    <div>Verify</div>
                    </CustomButton>
                    }
                </div>
                

                </InputWrapper>

                <SubmitButton disabled={!!loading} style={{marginTop: 12}} type={"submit"} active={!loading}>
                <div>Save</div>
                </SubmitButton>
            </Form>

            <CustomText style={{fontSize: 18, textAlign: 'left', marginTop: 40}}>
                <b>Wallet info:</b>
            </CustomText>
            <StyledWarning style={{width: 'fit-content'}}>To refill balance you must have MZR tokens <br/> on your metamask wallet</StyledWarning>
            <InputWrapper>
                <StyledLabel>Balance</StyledLabel>
                <StyledInput value={`${balance} MRZ`} disabled={true} placeholder={"john.smith@google.com"}></StyledInput>
            </InputWrapper>
            <InputWrapper>
                <StyledLabel>Amount</StyledLabel>
                <StyledInput onChange={(e: any) => setRefillAmount(Number(e.target.value))} type={'number'} disabled={false}
                            placeholder={"1.00"}></StyledInput>
                <CustomButton disabled={!loading || !refillAmount} onClick={handleReffil} style={{marginTop: 12}}
                            active={!loading || !refillAmount}>
                Refill
                </CustomButton>
            </InputWrapper>

            <CustomText style={{fontSize: 18, textAlign: 'left', marginBottom: 12, marginTop: 40}}>
                <b>Withdraw:</b>
            </CustomText>
            <InputWrapper>
                <StyledLabel>Amount</StyledLabel>
                <StyledInput onChange={(e: any) => setWithdrawAmount(Number(e.target.value))} type={'number'} disabled={false}
                            placeholder={"1.00"}></StyledInput>
                <CustomButton disabled={!loading || !refillAmount} type={'button'} onClick={handleWithdraw} style={{marginTop: 12}}
                            active={!loading || !refillAmount}>
                <div>Widthdraw</div>
                </CustomButton>
            </InputWrapper>
            </ButtonsBox>
        </Container>
        </Wrapper>
        <Modal styles={{
            modal: {
                backgroundColor: "rgb(23, 24, 29)"
            },
            close: {
                "&:hover": {
                    backgroundColor: "red"
                }
            }

        }} opened={isModalOpen} onClose={() => setIsModalOpen(!isModalOpen)} title={''} centered={true}>
            <CustomText style={{fontSize: 28, marginBottom: 16}}>{modalTitle}</CustomText>
            <Stack style={{gap: 16}}>
                <Stack style={{gap: 8}}>
                    <CustomText style={{color: "#fa5252", textAlign: 'center', fontSize: 14, marginBottom: "10px"}}>
                        <b>{modalContent}</b>
                    </CustomText>
                </Stack>
                <Stack align={"center"}>
                    <CustomButton onClick={() => setIsModalOpen(!isModalOpen)}>Close</CustomButton>
                </Stack>
            </Stack>
        </Modal>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.background};
`;
const LogoText = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin-top: 20px;
  height: 100px;
  color: ${Colors.textWhite};
`;

const Form = styled.form``

const Container = styled.div`
  width: 700px;
  margin: 20px;
  //min-height: calc(100% - 100px);
  padding: 40px;
  position: relative;
  background-color: rgb(23, 24, 29);
  border-radius: 4px;
  border: 1px solid rgba(255, 254, 241, 0.1);
  backdrop-filter: blur(100px);
  box-shadow: 0px 0px 100px -20px rgba(150, 104, 64, 0.75);
`;

const Header = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: ${Colors.textWhite};
  margin: 0px 0px 30px;
`;

const ErrorMessage = styled.div`
    color: #fa5252;
    font-size: calc(0.875rem - 0.125rem);
    line-height: 1.2;
    display: block;
    margin-top: 3px;
`

const ButtonsBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const CustomButton = styled("div")<any>`
  height: 49px;
  display: flex;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid ${(props) =>
          props.active ? "rgb(161, 119, 49)" : "rgba(255, 254, 241, 0.2)"};
  background: ${(props) =>
          props.active
                  ? "rgb(35, 32, 33)"
                  : "radial-gradient(202.04% 202.04% at 50% 111.22%, rgba(255, 254, 241, 0.1) 0%, rgba(120, 120, 120, 0.1) 23.95%);"};

  outline: none !important;
  transition: border-color 0.2s ease 0s, background-color 0.3s ease 0s;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
          props.active ? "rgb(242, 177, 50)" : Colors.textWhite};

  &:hover {
    ${(props) =>
            props.active
                    ? "border-color: rgb(193, 141, 53);    background-color: rgb(38, 35, 33);"
                    : `background: radial-gradient(202.04% 202.04% at 50% 111.22%, rgba(255, 254, 241, 0.2) 0%, rgba(120, 120, 120, 0.2) 23.95%);`}
  }
`;

const SubmitButton = styled.button<any>`
  height: 49px;
  display: flex;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid ${(props) =>
          props.active ? "rgb(161, 119, 49)" : "rgba(255, 254, 241, 0.2)"};
  background: ${(props) =>
          props.active
                  ? "rgb(35, 32, 33)"
                  : "radial-gradient(202.04% 202.04% at 50% 111.22%, rgba(255, 254, 241, 0.1) 0%, rgba(120, 120, 120, 0.1) 23.95%);"};

  outline: none !important;
  transition: border-color 0.2s ease 0s, background-color 0.3s ease 0s;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
          props.active ? "rgb(242, 177, 50)" : Colors.textWhite};

  &:hover {
    ${(props) =>
            props.active
                    ? "border-color: rgb(193, 141, 53);    background-color: rgb(38, 35, 33);"
                    : `background: radial-gradient(202.04% 202.04% at 50% 111.22%, rgba(255, 254, 241, 0.2) 0%, rgba(120, 120, 120, 0.2) 23.95%);`}
  }
}`

export const CustomText = styled.p`
  text-align: center;
  color: ${Colors.textGray};
  font-size: 13.3px;
  line-height: 160%;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

`

const StyledInput = styled.input<any>`
  padding: 0px 15px;
  height: 48px;
  width: 100%;
  outline: none;
  cursor: text;
  margin: 0px;
  color: ${(props) => props.error ? "#fa5252" : "rgb(255, 254, 241)"};
  background-color: rgba(255, 254, 241, 0.05);
  border-radius: 4px;
  border: 1px solid ${(props) => props.error ? "#fa5252" : "rgba(255, 254, 241, 0.1)"};
  font-size: 15px;
`

const StyledLabel = styled.label`
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  text-align: left;
  color: ${Colors.textWhite};
  margin: 0px 8px 8px;
`

const StyledWarning = styled.div`
  display: flex;
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid red;
  color: red;
  font-size: 14px;
`
