const Colors = {
  background: "#17181d",
  textWhite: "rgb(255, 254, 241)",
  textGray: "rgb(150, 149, 141)",
  tag1: {
    text: "rgb(250, 190, 101)",
    background: "rgba(250, 190, 101, 0.1)",
    border: "rgba(250, 190, 101, 0.35)",
  },
  tag2: {
    text: "rgb(94, 124, 241)",
    background: "rgb(30, 34, 50)",
    border: "rgb(52, 65, 117)",
  },
  tag3: {
    text: "rgb(0, 164, 235)",
    background: "rgba(0, 164, 235, 0.1)",
    border: "rgba(0, 164, 235, 0.35)",
  },
  tag4: {
    text: "rgb(226, 97, 97)",
    background: "rgba(226, 97, 97, 0.1)",
    border: "rgba(226, 97, 97, 0.35)",
  },
  tag5: {
    text: "rgb(9, 179, 116)",
    background: "rgba(9, 179, 116, 0.1)",
    border: "rgb(9, 179, 116)",
  },
};

export default Colors;
