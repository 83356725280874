import styled from "styled-components";
import Header from "../../components/Header";
import Colors from "../../constants/Colors";
import { useState } from "react";
import ArticleCard from "./ArticleCard";

export interface IArticle {
  id: number;
  img: string;
  tagId: string;
  tag: string;
  title: string;
  text: string;
  avatar: string;
  name: string;
  date: string;
}

const tags: string[] = [
  "All",

  "Company News",

  "Life at Starlight",

  "Guides",

  "Product Releases",

  "Resources",
];

const cards: IArticle[] = [
  {
    id: 0,
    img: "https://uploads-ssl.webflow.com/632c4f68f7447c23e3b5d6cd/640674c37a08d1639fa85102_altair-p-800.png",
    tagId: "tag1",
    tag: "Product Releases",
    title: "Introducing Altair",
    text: "Our latest release helping teams stay on top of their treasury, save time on payments, and streamline bookkeeping.",
    avatar:
      "https://uploads-ssl.webflow.com/632c4f68f7447c23e3b5d6cd/63b5ebc1a762af03411fffbd_IMG_8691%20copy-p-500.jpg",
    name: "Tiffany Chang",
    date: "March 7, 2023",
  },
  {
    id: 1,
    img: "https://uploads-ssl.webflow.com/632c4f68f7447c23e3b5d6cd/63f54c71edec728012649f3e_Main%20(1)-p-800.png",
    tagId: "tag2",
    tag: "Guides",
    title: "Protecting Your Web3 Business: Why Separating Wallets is a Must",
    text: "Walking web3 business owners and operators through the importance and best practices of separating business and company wallets.",
    avatar:
      "https://uploads-ssl.webflow.com/632c4f68f7447c23e3b5d6cd/63b5ebc1a762af03411fffbd_IMG_8691%20copy-p-500.jpg",
    name: "Tiffany Chang",
    date: "February 21, 2023",
  },
  {
    id: 2,
    img: "https://uploads-ssl.webflow.com/632c4f68f7447c23e3b5d6cd/6434321debae1afd87163c56_Main-p-800.png",
    tagId: "tag3",
    tag: "Company News",
    title: "Starlight Achieves SOC2 Type I Certification",
    text: "Another milestone in our commitment to data protection.",
    avatar:
      "https://uploads-ssl.webflow.com/632c4f68f7447c23e3b5d6cd/6384ef32f9ab0c39723a8109_IMG_8775%20(1)-p-500.jpg",
    name: "Marcella Plazas",
    date: "April 10, 2023",
  },
  {
    id: 3,
    img: "https://uploads-ssl.webflow.com/632c4f68f7447c23e3b5d6cd/6434321debae1afd87163c56_Main-p-800.png",
    tagId: "tag4",
    tag: "Life at Starlight",
    title: "Discovering the WEB3",
    text: "Meet Art, one of the engineers behind our product.",
    avatar:
      "https://uploads-ssl.webflow.com/632c4f68f7447c23e3b5d6cd/63e40ee596c60c5026e61d93_Featured-p-800.png",
    name: "Marcella Plazas",
    date: "March 21, 2023",
  },
];

const BlogPage = () => {
  const [activeTag, setActiveTag] = useState(tags[0]);

  const filteredArticles = cards.filter(
    (item) => item.tag === activeTag || activeTag === "All"
  );

  return (
    <Container>
      <Header />
      <Hero>
        <Blur />

        <HeroContent>
          <Title>Blog</Title>
          <TagsList>
            {tags.map((item, index) => {
              return (
                <Tag
                  key={index}
                  isActive={item === activeTag}
                  onClick={() => setActiveTag(item)}
                >
                  {item}
                </Tag>
              );
            })}
          </TagsList>
        </HeroContent>

        <Gradient />
      </Hero>

      <Padding>
        <Content>
          {filteredArticles.length > 0 ? (
            <>
              <ArticleCard big {...filteredArticles[0]}></ArticleCard>

              {filteredArticles.slice(1).map((item, index) => {
                return <ArticleCard key={index} {...item}></ArticleCard>;
              })}
            </>
          ) : (
            <Empty>Empty category</Empty>
          )}
        </Content>
      </Padding>
    </Container>
  );
};

export default BlogPage;

const Container = styled.div`
  flex: 1;
  background-color: #17181d;
  color: "red";
  overflow-x: hidden;
`;

const Hero = styled.div`
  padding-top: 190px;
  padding-bottom: 10px;
  position: relative;
  overflow: hidden;
  @media (min-width: 991px) {
    padding-bottom: 70px;
  }
`;

const Blur = styled.div`
  z-index: 0;
  width: 1400px;
  height: 86px;
  opacity: 0.5;
  filter: blur(120px);
  background-image: linear-gradient(
    #f1aa21,
    #f19c5e 25%,
    #a01547 50%,
    #c27df9 75%,
    #cc9bf2
  );
  border-radius: 100px;
  position: absolute;
  top: 8px;
  bottom: auto;
  left: 5%;

  transform: translateZ(0) rotateZ(-14deg);
`;
const HeroContent = styled.div`
  position: relative;
  z-index: 1;
  margin: auto;
  padding: 0 20px;
  max-width: 750px;
`;
const Title = styled.h1`
  color: ${Colors.textWhite};
  font-weight: 600;
  line-height: 1.1;
  letter-spacing: 0;
  font-size: 36px;
`;
const TagsList = styled.div`
  margin: 40px 0 30px;

  max-width: 700px;
  overflow-x: auto;

  display: flex;
  flex-direction: row;
  gap: 20px;
`;
const Tag = styled.div<any>`
  cursor: pointer;

  white-space: nowrap;

  ${(props) =>
    props.isActive
      ? `
    border-bottom: 2px solid #fff;
    color: ${Colors.textWhite};
    padding-bottom: 8px;
  `
      : `color: ${Colors.textGray};`};
`;

const Gradient = styled.div`
  z-index: 0;
  height: 250px;
  background-image: linear-gradient(rgba(23, 24, 29, 0), #17181d);
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
`;

const Padding = styled.div`
  padding: 0 15px;

  @media (min-width: 991px) {
    padding: 0 35px;
  }
`;
const Content = styled.div`
  max-width: 1070px;

  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;

  @media (min-width: 991px) {
    margin: 0 auto;
    grid-template-columns: 1fr 1fr;
    width: 90%;
  }
`;

const Empty = styled.div`
  grid-column: span 2;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${Colors.textWhite};
`;
