import { Link } from "react-router-dom";
import styled from "styled-components";
import Colors from "../../constants/Colors";
import { IArticle } from ".";
import ArrowSvg from "../../components/icons/ArrowSvg";

interface IColors {
  text: string;
  background: string;
  border: string;
}

interface IProps extends IArticle {
  big?: true;
}

const ArticleCard = ({
  big,
  id,
  img,
  tagId,
  tag,
  title,
  text,
  avatar,
  name,
  date,
}: IProps) => {
  const tagColors: IColors = Colors[
    `${tagId}` as keyof typeof Colors
  ] as IColors;

  return (
    <Container to={`/article/${id}`} className={big ? "big" : ""}>
      <ImgBox>
        <img src={img} alt="article illustration" />
      </ImgBox>

      <Card className={big ? "big" : ""}>
        <CardTop>
          <CardHeader>
            <Tag
              text={tagColors.text}
              background={tagColors.background}
              border={tagColors.border}
            >
              {tag}
            </Tag>

            <ArrowBox>
              <div className="text">Read more</div>
              <div className="arrow">
                <ArrowSvg></ArrowSvg>
              </div>
            </ArrowBox>
          </CardHeader>

          <h2>{title}</h2>
          <p>{text}</p>
        </CardTop>
        <CardBottom>
          <ImgBox>
            <img src={avatar} alt={"avatar"} />
          </ImgBox>
          <div className="name">{name}</div>

          <div className="divider" />
          <div className="date">{date}</div>
        </CardBottom>
      </Card>
    </Container>
  );
};

export default ArticleCard;

const Container = styled(Link)`
  position: relative;
  background-color: #1d1d1d;
  border-radius: 10px;

  display: grid;

  &.big {
    margin-bottom: 60px;
    min-height: 390px;

    min-height: 390px;

    & h2 {
      font-size: 30px;
    }

    &:after {
      content: "";
      position: absolute;
      z-index: 3;
      bottom: -40px;
      left: 0px;
      background-image: linear-gradient(
        to left,
        rgba(51, 55, 58, 0) 0%,
        rgb(75, 81, 86) 50%,
        rgba(51, 55, 58, 0) 100%
      );
      width: 100%;
      height: 1px;
    }

    & > div:first-of-type {
      width: 100%;
      padding-top: 0%;
      border-radius: 0px 9px 9px 0px;
      overflow: hidden;
    }

    @media (min-width: 991px) {
      margin-bottom: 120px;
      margin-left: -50px;
      margin-right: -50px;
      grid-column: span 2;

      grid-column-gap: 16px;
      grid-row-gap: 16px;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      grid-auto-columns: 1fr;

      &:after {
        bottom: -70px;
      }
    }
  }

  & > div:first-of-type {
    border-radius: 9px 9px 0px 0px;
    overflow: hidden;
  }

  &:hover .text {
    opacity: 1;
    text-decoration: underline;
  }
`;

const ImgBox = styled.div`
  overflow: hidden;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 30px;

  @media (min-width: 991px) {
    padding: 30px 30px 40px;
    &.big {
      padding: 50px;
      order: -1;
    }
  }
`;

const CardTop = styled.div`
  align-self: stretch;
  margin-bottom: 48px;

  & h2 {
    margin-top: 0;
    margin-bottom: 20px;

    font-size: 22px;

    line-height: 1.3;
    font-weight: 600;
    color: rgb(255, 251, 194);
  }
  & p {
    color: rgb(189, 183, 174);
    font-size: 17px;

    margin-bottom: 0;
    margin-top: 0;
  }
`;
const CardHeader = styled.div`
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin-right: 0;
`;

const Tag = styled.div<IColors>`
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  align-items: center;
  margin-bottom: 0;
  padding: 1px 8px;
  font-size: 14px;
  display: flex;

  color: ${(props) => props.text};
  background-color: ${(props) => props.background};
  border-color: ${(props) => props.border};
`;

const ArrowBox = styled.div`
  color: #96958d;
  align-items: center;
  font-size: 15px;
  transition: color 0.2s;
  display: flex;

  text-underline-offset: 5px;
  max-width: 100%;

  & .text {
    font-size: 15px;
    opacity: 0;
    margin-right: 8px;
    transition: opacity 0.2s;
  }
  & .arrow {
    margin-bottom: -3px;
    line-height: 1;
    font-size: 15px;
  }
`;

const CardBottom = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: row;
  align-items: center;

  & img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 10px;
  }
  & .name {
    font-size: 15px;
    color: rgb(255, 251, 194);
  }
  & .divider {
    width: 3px;
    height: 3px;
    background-color: #595952;
    border-radius: 50%;
    margin-left: 8px;
    margin-right: 8px;
    background-color: rgb(64, 70, 72);
  }
  & .date {
    color: rgb(189, 183, 174);
    font-size: 15px;
  }
`;
