import {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import Colors from "../../constants/Colors";
import {usePasswordRestore} from "./hooks/usePasswordRestore";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required")
});


export const RestorePassword = () => {
    const [codeSent, setCodeStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {handleRestoreSend, handleRestoreCheck, handleChangePassword} = usePasswordRestore();
    const [isCodeCorrect, setIsCodeCorrect] = useState(false);
    const [timer, setTimer] = useState(59);

    const {
        handleSubmit,
        control,
        formState: {errors},
        setError,
        clearErrors
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: "",
            code: "",
            password: ""
        },
    });

    const onSubmit = useCallback(async (data: any) => {
        clearErrors()
        if (!codeSent) {
            setIsLoading(true);
            try {
                await handleRestoreSend(data.email);
                setCodeStatus(true)
            } catch (e) {
                setCodeStatus(false);
            }

            setIsLoading(false);
            return
        } else {
            console.log('data', data)
            if (data.code?.length !== 4 && !data.password) {
                setError("code", {
                    type: "manual",
                    message: "Verification code must contain 4 characters",
                });
                return

            }
            if (!isCodeCorrect) {
                try {
                    await handleRestoreCheck(data.code);
                    setIsCodeCorrect(true);
                } catch (e) {
                    setError("code", {
                        type: "manual",
                        message: "Verification failed. Please try again.",
                    });
                    setIsCodeCorrect(false);
                    return
                }
            }

        }
        if (isCodeCorrect && data.password.length < 4) {
            setError("password", {
                type: "manual",
                message: "password must be at least 4 characters",
            });
            return
        }
        if (data.password) {
            await handleChangePassword(data.password)
        }
    }, [handleChangePassword, setIsCodeCorrect, handleRestoreCheck, codeSent, setError, isCodeCorrect, handleRestoreSend, clearErrors])


    useEffect(() => {
        if (codeSent && timer > 0) {
            const interval: any = setInterval(() => {
                if (timer === 0) return clearInterval(interval);
                setTimer(timer - 1);
            }, 1000);

            return () => clearInterval(interval);
        }
        if (!codeSent) setTimer(60);
    }, [codeSent, timer]);

    return (
        <Wrapper>
            <LogoText>Mazuri</LogoText>
            <Container>
                <Header>Restore Password</Header>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ButtonsBox>
                        <InputWrapper>
                            <StyledLabel>Email</StyledLabel>
                            <Controller
                                name="email"
                                control={control}
                                render={({field}) => (
                                    <StyledInput
                                        {...field}
                                        disabled={codeSent}
                                        placeholder="john.smith@google.com"
                                    />
                                )}
                            />
                            <Error>{errors.email?.message}</Error>
                        </InputWrapper>
                        {!codeSent ? (
                            <CustomButton type="submit" active={!isLoading}>
                                Send code
                            </CustomButton>
                        ) : (
                            <>
                                <InputWrapper>
                                    <StyledLabel>Code</StyledLabel>
                                    <Controller
                                        name="code"
                                        control={control}
                                        render={({field}) => (
                                            <StyledInput disabled={isCodeCorrect} {...field} placeholder="XXXX"/>
                                        )}
                                    />
                                    <Error>{errors.code?.message}</Error>
                                </InputWrapper>
                            </>
                        )}

                        {isCodeCorrect && (
                            <InputWrapper>
                                <StyledLabel>Password</StyledLabel>
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({field}) => (
                                        <StyledInput
                                            type={'password'}
                                            {...field}
                                        />
                                    )}
                                />
                                <Error>{errors.password?.message}</Error>
                                {/* Добавьте валидацию для поля пароля, если необходимо */}
                            </InputWrapper>
                        )}

                        {(isCodeCorrect || codeSent) && <CustomButton
                            type="submit"
                            disabled={isLoading || errors.code}
                            active={!isLoading && !errors.code}
                        >
                            Confirm
                        </CustomButton>
                        }
                    </ButtonsBox>
                </form>
                <Divider/>
                {(codeSent && !isCodeCorrect) && (
                    <ButtonsBox>
                        <CustomText style={{width: "100%"}}>
                            Resend code in 00:{timer < 10 ? `0${timer}` : timer}
                        </CustomText>
                        <CustomButton
                            type="button"
                            disabled={!isLoading && !timer}
                            onClick={handleSubmit(onSubmit)}
                            active={!isLoading && !timer}
                        >
                            Resend code
                        </CustomButton>
                    </ButtonsBox>
                )}

            </Container>
        </Wrapper>
    );
};


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.background};
  height: 100%;

  width: 100%;
`;

const LogoText = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 65px;
  color: ${Colors.textWhite};
`;
const Container = styled.div`
  width: 480px;
  height: auto;
  padding: 40px;
  position: relative;
  background-color: rgb(23, 24, 29);
  border-radius: 4px;
  border: 1px solid rgba(255, 254, 241, 0.1);
  backdrop-filter: blur(100px);
  box-shadow: 0px 0px 100px -20px rgba(150, 104, 64, 0.75);
`;

const Header = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: ${Colors.textWhite};
  margin: 0px 0px 30px;
`;

const ButtonsBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const CustomButton = styled.button<any>`
  height: 49px;
  display: flex;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid ${(props) =>
          props.active ? "rgb(161, 119, 49)" : "rgba(255, 254, 241, 0.2)"};
  background: ${(props) =>
          props.active
                  ? "rgb(35, 32, 33)"
                  : "radial-gradient(202.04% 202.04% at 50% 111.22%, rgba(255, 254, 241, 0.1) 0%, rgba(120, 120, 120, 0.1) 23.95%);"};

  outline: none !important;
  transition: border-color 0.2s ease 0s, background-color 0.3s ease 0s;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
          props.active ? "rgb(242, 177, 50)" : Colors.textWhite};

  &:hover {
    ${(props) =>
            props.active
                    ? "border-color: rgb(193, 141, 53);    background-color: rgb(38, 35, 33);"
                    : `background: radial-gradient(202.04% 202.04% at 50% 111.22%, rgba(255, 254, 241, 0.2) 0%, rgba(120, 120, 120, 0.2) 23.95%);`}
  }
`;
const CustomText = styled.p`
  margin: 20px auto 0px;
  max-width: 320px;
  text-align: center;
  color: ${Colors.textGray};
  font-size: 13.3px;
  line-height: 160%;
`;

const Divider = styled.div`
  width: 120px;
  height: 1px;
  background-color: rgba(255, 254, 241, 0.1);
  margin: 20px auto 0px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

`


const StyledInput = styled.input`
  padding: 0px 15px;
  height: 48px;
  width: 100%;
  outline: none;
  cursor: text;
  margin: 0px;
  color: rgb(255, 254, 241);
  background-color: rgba(255, 254, 241, 0.05);
  border-radius: 4px;
  border: 1px solid rgba(255, 254, 241, 0.1);
  font-size: 15px;
`

const StyledLabel = styled.label`
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  text-align: left;
  color: ${Colors.textWhite};
  margin: 0px 8px 8px;
`

const Error = styled.div`
  color: red;
  margin-top: 4px
`
