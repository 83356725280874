import {useCallback, useState} from "react";
import {api} from "../../../App";
import {useNavigate} from "react-router-dom";
import {ProjectRoutes} from "../../../constants/ProjectRoutes";
import {handleError} from "../../ErrorHandler/ErrorHandler";

export const usePasswordRestore = () => {
    const [token, setToken] = useState('')
    const [code, setCode] = useState('')
    const [verifiedCode, setVerifiedCode] = useState(false)
    const navigate = useNavigate()

    const handleRestoreSend = useCallback(async (email: string) => {
        const res = await api.auth.restoreSend(email)
        if (!res) return
        setToken(res.verificationToken)
    }, [setToken])

    const handleRestoreCheck = useCallback( (verificationCode: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                setCode(verificationCode)
                await api.auth.restoreCheck({
                    verificationToken: token,
                    verificationCode
                })
                setVerifiedCode(true)
                return resolve(true)
            } catch (e: any) {
                setVerifiedCode(false)
                const error = e.response.data
                handleError({id: error.code, title: 'Error', message: error.error})
                return reject(error.error)
            }
        })


    }, [token, setCode, setVerifiedCode])

    const handleChangePassword = useCallback(async (password: string) => {
        try {
            await api.auth.changeRestoredPassword({
                verificationToken: token,
                verificationCode: code,
                password
            })

            window.alert('Password successfully changed')

            navigate(ProjectRoutes.SignIn)
        } catch (e) {
            console.log(`e`, e)
            return new Error(`wrong code`)
        }
    }, [code, token, navigate])


    return {
        verifiedCode,
        handleChangePassword,
        handleRestoreCheck,
        handleRestoreSend
    }
}
