import {useCallback} from 'react';
import {api} from "../../../App";
import useAuthStore from "./useAuthStore";
import {useAuth} from "./useAuth";
import {useUser} from "./useUser";
import {useWeb3React} from "@web3-react/core";
import {connector} from "../../../utils/Connectors";
import {useNavigate} from "react-router-dom";
import {ProjectRoutes} from "../../../constants/ProjectRoutes";
import {handleError} from "../../ErrorHandler/ErrorHandler";

const Window = window as any
export function useWallet() {
  const { setConnect, setUser, user, setLoggedIn } = useAuthStore();
  const {checkAuth} = useAuth()
  const {data} = useUser()
  const {activate} = useWeb3React()
  const navigate = useNavigate()

  const connect = useCallback(async () => {
    console.log(`connecting`)
    if (!Window.ethereum) {
      return console.log('[USE_WALLET]', 'MetaMask not detected');
    }

    try {
      if(data?.wallet) {
        await activate(connector)
      }
      const [ address ] = await Window.ethereum.request({ method: 'eth_requestAccounts' }).catch((e: any) => console.error(e));
      if (!address) return;

      // Get login code by user address & network
      if(user?.wallet) {
        if(user.wallet !== address) {
          handleError({id: "0", title: "Error", message: "Current address doesn't match address linked to metamask"})
          return
        }
        setConnect(true);
        navigate(ProjectRoutes.account)
        return
      }
      const loginCode = await api.auth.getLoginCode(address);

      if(!loginCode) return

      if(!loginCode.isSuccess || !loginCode.code) {
        return
      }
      Window.ethereum.on('accountsChanged', (accounts: string[]) => {
        if (!accounts.length) {
          setLoggedIn(false)

          console.log(`DISCONNECTED_WALLET`)
        }

      })

      // Get user signature from MetaMask Extension
      const signature = await Window.ethereum.request({
        method: 'personal_sign',
        params: [ loginCode.code, address ]
      });

      await api.auth.confirmCode({walletAddress: address, signature})
      await checkAuth()

      setConnect(true);

      return {
        code: loginCode.code,
        address,
        signature,
      };
    } catch (e: any) {
      const error = e.response.data
      if(error.code === 1) return
      else {
        handleError({id: error.code, title: 'error', message: error.message || "unexpected error"})
      }

      if(e.code === -32603) return
    }

  }, [ setConnect, checkAuth, setLoggedIn, activate, navigate, data?.wallet, user?.wallet]);


  const disconnect = useCallback(() => {
    setUser(null);
    setConnect(false);
  }, [ setConnect, setUser ]);

  return {
    user,
    connect,
    disconnect
  };
}

