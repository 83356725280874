import { Base } from "./Base";
import { ApiResponse } from "./types/api";
import {
  AuthForm,
  AuthResponse,
  CodeResponse,
  ConfirmCodePayload, RestoreCheckPayload,
  RestoreCheckResponse, RestorePasswordPayload,
  RestoreSendResponse,
  User
} from "./types/Login";
import {handleError} from "../features/ErrorHandler/ErrorHandler";

export default class Auth extends Base {
  async getLoginCode(address: string) {
    try {
      const response = await this.request.get<ApiResponse<CodeResponse>>(
          `${this.baseUrl}/user/${address}/nonce`
      )

      return {
        isSuccess: response.data.success,
        code: response.data.data.nonce
      }
    } catch (e: any) {
        const error = e.response.data
        handleError({id: error.code, title: 'Error', message: error.error})
    }

  }

  async confirmCode({walletAddress, signature}: ConfirmCodePayload) {
    const response = await this.request.post<ApiResponse<any>>(`${this.baseUrl}/user/${walletAddress}/signature?secret=secret`, {signature: signature})

    console.log(`confirmResponse`, response.data.data)
    return response.data.data
  }

  async getUser() {
    const response = await this.request.get<ApiResponse<User>>(
      `${this.baseUrl}/user/whoami`
    )
    return response.data.data
  }

  async register(payload: AuthForm) {
    const response = await this.request.post<ApiResponse<AuthResponse>>(`${this.baseUrl}/public/register`, payload)

    return response.data.data
  }

  async login(payload: AuthForm) {
    const response = await this.request.post<ApiResponse<AuthResponse>>(`${this.baseUrl}/public/login`, payload)

    return response.data.data
  }

  async restoreSend(email: string) {
    try {
      const response = await this.request.post<ApiResponse<RestoreSendResponse>>(`${this.baseUrl}/public/user-restore-send`, {
        email
      })

      return response.data.data
    } catch (e: any) {
      const error = e.response.data
      handleError({id: error.code, title: 'Error', message: error.error})
      throw new Error(error.error)
    }

  }

  async restoreCheck(payload: RestoreCheckPayload) {
      const response = await this.request.post<ApiResponse<RestoreCheckResponse>>(`${this.baseUrl}/public/user-restore-check`, payload)

      return response.data.data
  }

  async changeRestoredPassword(payload: RestorePasswordPayload) {
    try {
      const response = await this.request.post<ApiResponse<any>>(`${this.baseUrl}/public/user-restore-password`, payload)

      return response.data.data
    } catch (e: any) {
      const error = e.response.data
      handleError({id: error.code, title: 'Error', message: error.error})
      return new Error(error.error)
    }
  }


  // async register(input: RegisterForm) {
  //     const response = await this.request.post<ApiResponse<RegisterResponse>>(
  //         `${this.baseUrl}/public/registration/merchant`,
  //         input
  //     );
  //     return response.data.data;
  // }
}
