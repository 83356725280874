export enum ProjectRoutes {
  SignIn = "/auth/sign-in",
  SignUp = "/auth/sign-up",
  Blog = "/home",
  account = '/account',
  walletConnect = '/wallet',
  confirmEmail = "/email-confirm",
  phoneVerification = "/phone-verification",
  restorePassword = "/restorePassword"
}
