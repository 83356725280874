import { useQuery } from "react-query";
import {api} from "../App";

export const useServerConstants = () => {
  const { data, refetch, isLoading, isError, remove } = useQuery(["CONSTANTS"], () => api.user.getConstants(), {retry: 0});

  return {
    data,
    refetch,
    isLoading,
    isError,
    remove,
  };
};
