import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import {ProjectRoutes} from "../../constants/ProjectRoutes";
import {AccountPage, CustomButton, CustomText} from "../Account/AccountPage";
import {WallectConnector} from "../Account/WallectConnector";
import {useUser} from "../Auth/hooks/useUser";
import {ConfirmEmail} from "../Account/ConfirmEmail";
import {useEffect, useMemo, useState} from "react";
import {useAutoConnect} from "../../blockchain/useAutoConnect";
import {PhoneVerification} from "../Account/PhoneVerification";
import {useWeb3React} from "@web3-react/core";
import {handleError} from "../ErrorHandler/ErrorHandler";
import {Modal, Stack} from "@mantine/core";

export const SecureRoutes = () => {
    const {data} = useUser()
    const navigate = useNavigate()
    const connected = useAutoConnect()
    const {account} = useWeb3React()
    const [isModalOpen, setIsModalOpen] = useState(true)

    const isEmailVerified = useMemo(() => {
        return data?.isEmailVerified
    }, [data])

    const isWalletConnected = useMemo(() => {
        return data?.wallet
    }, [data])

    const isCorectWallet = useMemo(() => {
        return account?.toLowerCase() === data?.wallet.toLowerCase()
    }, [data, account])

    useEffect(() => {
        console.log(`is correct waller`, isCorectWallet)
        if (!isEmailVerified) navigate(ProjectRoutes.confirmEmail)
        else if (!isWalletConnected || !connected ) navigate(ProjectRoutes.walletConnect)
        else if (data && account && !isCorectWallet) {
            handleError({id: "", title: 'Connect wallet error', message: "The connected wallet does not match the wallet linked to your account"})
            navigate(ProjectRoutes.walletConnect)
        }
        // else navigate(ProjectRoutes.account)
    }, [isEmailVerified, isWalletConnected, navigate, connected, isCorectWallet, data, account])


    return (
        <>
            <Routes>
                <Route path={ProjectRoutes.account} element={<AccountPage/>}/>
                <Route path={ProjectRoutes.walletConnect} element={<WallectConnector/>}/>
                <Route path={ProjectRoutes.confirmEmail} element={<ConfirmEmail/>}/>
                <Route path={ProjectRoutes.phoneVerification} element={<PhoneVerification/>}/>
                <Route
                    path="*"
                    element={<Navigate replace to={ProjectRoutes.account}/>}
                />
            </Routes>
            <Modal styles={{
                modal: {
                    backgroundColor: "rgb(23, 24, 29)"
                },
                close: {
                    "&:hover": {
                        backgroundColor: "red"
                    }
                }

            }} opened={isModalOpen && !!account} onClose={() => setIsModalOpen(!isModalOpen)} title={''} centered={true}>
                <CustomText style={{fontSize: 28, marginBottom: 16}}>Wallet information</CustomText>
                <Stack style={{gap: 16}}>
                    {account && !isCorectWallet && <CustomText style={{fontSize: 16, color: 'red', textAlign: 'justify'}}>
                        <b>The connected wallet does not match the wallet linked to your account. <br/>
                            You cannot use the site with this address
                        </b>
                    </CustomText>}
                    <Stack style={{gap: 8}}>
                        <CustomText style={{color: isCorectWallet ? 'green' : "red", textAlign: 'left', fontSize: 14}}>
                            <b>Linked metamask address to account:</b> {data?.wallet}
                        </CustomText>
                        <CustomText style={{color: isCorectWallet ? 'green' : "red", textAlign: 'left', fontSize: 14}}>
                            <b>Connected metamask address:</b> {account}
                        </CustomText>

                        {!isCorectWallet && <CustomText style={{textAlign: 'left', fontSize: 14, marginTop: 16}}>
                            <b>Please open metamask to change connected wallet</b>
                        </CustomText>}
                    </Stack>


                    <Stack align={"center"}>
                        <CustomButton onClick={() => setIsModalOpen(!isModalOpen)}>Confirm</CustomButton>
                    </Stack>

                </Stack>



            </Modal>
        </>

    )
}
