import {useCallback, useState} from "react";
import {api} from "../../../App";

export const useVerification = () => {
  const [token, setToken] = useState<string>('')
  const sendVerificationCode = useCallback(async(type: 'email' | 'phone') => {
    if(type === 'email') {
      const verificationToken = await api.user.getEmailCode()
      if(!verificationToken) return
      setToken(verificationToken.verificationToken)
    } else {
      const {verificationToken} = await api.user.getPhoneCode()
      setToken(verificationToken)
    }

  }, [setToken])

  const handleVerify = useCallback(async(code: string) => {
    try {
      await api.user.verificationCheck({verificationToken: token, verificationCode: code})
    } catch (e: any) {
      console.log(e.response)
    }
  }, [token])

  return {sendVerificationCode, handleVerify}
}
