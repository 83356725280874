import { useCallback, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import {connector} from "../utils/Connectors";



export function useMetaMaskListener(connected: boolean = false) {
  const { active, error, activate } = useWeb3React();

  // Connect Event
  const onConnect = useCallback(async () => {
    console.log('[MetaMask]:', 'Handling "connect" event');
    await activate(connector);
  }, [activate])

  // Chain Changed
  const onChainChanged = useCallback(async (chainId: string | number) => {
    console.log('[MetaMask]:', 'Handling "chainChanged" event with payload', chainId);
    await activate(connector);
  }, [activate])

  // Account Changed
  const onAccountChanged = useCallback(async (accounts: string[]) => {
    console.log('[MetaMask]:', 'Handling "accountsChanged" event with payload', accounts);

    if (!accounts.length) return
    await activate(connector);
  }, [activate])


  useEffect(() => {
    const { ethereum } = window as any;
    if (ethereum && ethereum.on && !active && !error && connected) {

      ethereum.on('connect', onConnect);
      ethereum.on('chainChanged', onChainChanged);
      ethereum.on('accountsChanged', onAccountChanged);

      return () => {
        if (!ethereum.removeListener) return

        ethereum.removeListener('connect', onConnect);
        ethereum.removeListener('chainChanged', onChainChanged);
        ethereum.removeListener('accountsChanged', onAccountChanged);
      };
    }
  }, [active, error, activate, onConnect, onChainChanged, onAccountChanged, connected]);
}
