import {SecureRoutes} from "./SecureRoutes";
import {PublicRoutes} from "./PublicRoutes";
import {useAuthorizeWatcher} from "../Auth/hooks/useAuthWatcher";
import {useAutoConnect} from "../../blockchain/useAutoConnect";
import {useMetaMaskListener} from "../../blockchain/useMetamaskListener";

export default function RootRouter() {
  const connected = useAutoConnect()
  useMetaMaskListener(connected)
  const { isAuthorized, canRender } = useAuthorizeWatcher();

  if (!canRender) return null;

  return (
    <>
      {isAuthorized ? <SecureRoutes/> : <PublicRoutes/>}
    </>
  );
}
