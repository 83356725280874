import { ethers } from 'ethers';
import { useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';

const Window = window as any
export function useSigner() {
  const { library } = useWeb3React();

  const signer = useMemo(() => {
    if (!Window.ethereum) return null;

    return library?.getSigner()
  }, [library]);

  function isSignerReady(signer: ethers.providers.JsonRpcSigner | null): signer is ethers.providers.JsonRpcSigner {
    return Boolean(signer);
  }

  return { signer, isSignerReady, library };
}

